import axios from 'axios'

export default {
  uploadDncFile (data, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', data)

    return axios.post('/dnc/upload_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },

  getStatus () {
    return axios.get(
      '/dnc/status'
    )
  },
}