<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="areaCodes.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ areaCodes.length }} DNC Area Codes
          </h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="areaCodes"
              :search="search"
              :items-per-page="50"
              :sort-by="['name']"
            >
              <template #item.created_at="{value}">
                {{ formatIsoDate(value, false) }}
              </template>
              <template #item.last_imported_at="{value}">
                {{ formatIsoDate(value, false) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>  
    </template>
    <template v-if="customerAreaCodes.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ customerAreaCodes.length }} Customer Area Codes
          </h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="customerAreaCodesHeaders"
              :items="customerAreaCodes"
              :search="search"
              :items-per-page="50"
              :sort-by="['name']"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>  
    </template>       
    <v-row>
      <template v-if="uploading">
        <v-progress-linear
          v-model="progress"
          color="light-blue"
          height="25"
          reactive
          striped
        >
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </template>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Upload DNC Files
            </v-card-title>
            <template>
              <v-file-input
                show-size
                label="Click to select files to upload."
                accept=".txt"
                @change="selectFile"
              />
            </template>
            <v-row dense>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  :disabled="file === undefined || file == '' || uploading"
                  @click="handleUpload()"
                >
                  Upload
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>    
  </v-container>
</template>
<script>
  import DncService from '@/services/DncService.js'
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'

  export default {
    name: 'ManageDNC',
    components: {
      ErrorMessage,
    },    
    mixins: [formatterMixin],
    data () {
      return {
        showErrorDialog: false,        
        loading: false,
        error: '',
        progress: 0,
        files: [],
        uploading: false,
        file: '',
        areaCodes: [],
        customerAreaCodes:  [],
        loadingMessage: 'Loading',
        search: '',
        headers: [
          { text: 'Area Code', value: 'area_code' },
          { text: 'Created At', value: 'created_at', align: 'center' },
          { text: 'Last Imported At', value: 'last_imported_at', align: 'center' },
        ],  
        customerAreaCodesHeaders: [
          { text: 'Area Code', value: 'area_code' },
          { text: 'Number of Phone Numbers', value: 'count' },
          { text: 'Supported by DNC List?', value: 'dnc_support' },
],       
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName', 'dealerGroup']),
    },
    watch: {
      accountId () {
        this.loadStatus()
      },
    },
    created () {
      this.loadStatus()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },   
      loadStatus: function () {
        this.loading = true
        this.loadingMessage = 'Loading Area Codes'
        DncService.getStatus()
          .then(response => {
            this.loading = false
            if (response.data) {
              this.areaCodes = response.data.dnc
              this.customerAreaCodes = response.data.customer_area_codes
            } else {
              this.areaCodes = []
              this.customerAreaCodes = []
            }
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },         
      handleUpload () {
        this.uploading = true
        this.progress = 1
        DncService.uploadDncFile(this.file, (event) => {
        })
          .then((response) => {
            this.progress = 100
            this.file = undefined
            this.uploading = false
          })
          .catch(() => {
            this.progress = 0
            this.file = undefined
            this.uploading = false
          })
      },
      selectFile (file) {
        this.progress = 0
        this.file = file
      },
    },
  }
</script>
